
import { Component, Vue } from "vue-property-decorator";
import pageBack from "src/components/page-back";
import globalConfig from "src/config/index";
import { EmployerAuthService } from "src/services";

@Component({
  components: {
    pageBack,
  },
})
export default class Login extends Vue {
  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-authoppo");
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 授权信息
   * @private
   * @returns any
   */
  private employerAuthInfo: any = {
    authContent: "",
    authTitle: "",
    id: null,
    isEnable: false,
  };

  private created() {
    this.getEmployerAuth();
  }

  /**
   * 获取授权信息
   * @private
   * @returns void
   */
  private async getEmployerAuth(): Promise<void> {
    try {
      let { content: result } = await EmployerAuthService.instance.getEmployerAuth();
      if (result && result.data) {
        this.employerAuthInfo = result.data;
      }
    } catch (err) {
      console.log(err);
    }
  }
}
