
import { Component, Vue } from "vue-property-decorator";
import { WechatService, UserService, OrderService, AddPackageService, EmployerAuthService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { tools, Pay } from "src/utils";
import globalConfig from "src/config/index";
import UAuthorization from "../authorization.vue";
import UAthorizationoppo from "../authorization-oppo.vue";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    UAuthorization,
    UAthorizationoppo,
  },
})
export default class SubmitOrder extends Vue {
  /**
   * 是否完善信息
   * @private
   * @returns boolean
   */
  private isPerfect: boolean = true;

  /**
   * 加项标题
   * @private
   * @returns any
   */
  private addItemTitle: string = "";

  /**
   * 是否选择日期
   * @private
   * @returns boolean
   */
  private showDate: boolean = false;

  /**
   * 加项包详情弹框
   * @private
   * @returns boolean
   */
  private showAddPackModal: boolean = false;

  /**
   * 当前加项包的项目
   * @private
   * @returns Array<any>
   */
  private addPackItems: Array<any> = [];

  /**
   * 显示确认弹框
   * @private
   * @returns boolean
   */
  private showDialog: boolean = false;

  /**
   * 选择日期时间段
   * @private
   * @returns string
   */
  private physicalTimePeriod: string = null;

  /**
   * 固定项目展开id集合
   * @private
   * @returns Array<number>
   */
  private fixedCollapseActive: Array<number | string> = [];

  /**
   * 加项项目展开id集合
   * @private
   * @returns Array<number>
   */
  private collapseActiveNames: Array<number | string> = [];

  /**
   * 取消授权报告弹框
   * @private
   * @returns boolean
   */
  private showOverlay: boolean = false;

  /**
   * 是否同意协议
   * @private
   * @returns boolean
   */
  private checkedAgreement: boolean = true;

  /**
   * 企业appid
   * @private
   * @returns boolean
   */
  public appid: string = "";

  /**
   * 机构相关信息
   * @private
   * @returns any
   */
  private hospitalInfo: any = {
    address: "",
    hospitalId: null,
    hospitalName: null,
    latitude: null,
    longitude: null,
  };

  /**
   * 用户相关信息
   * @private
   * @returns any
   */
  private memberInfo: any = {
    cardNo: null,
    cardType: null,
    cardTypeStr: null,
    employerMemberId: null,
    memberName: null,
    phone: null,
  };

  /**
   * 套餐信息
   * @private
   * @returns any
   */
  private packageInfo: any = {
    baseItemTypes: [],
    basePrice: null,
    couponUsingAmount: null,
    needPayAmount: null,
    optionalItemTypes: [],
    optionalPrice: null,
    packageId: null,
    packageName: null,
    walletUsingAmount: null,
  };

  /**
   * 是否在加载中
   * @private
   * @returns boolean
   */
  private loading: boolean = false;

  /**
   * 选择日期
   * @private
   * @returns any
   */
  private selectOrderDate: string = null;

  /**
   * 是否显示用户授权协议
   * @private
   * @returns boolean
   */
  public showAuthorization: boolean = false;

  /**
   * 是否显示oppo授权协议
   * @private
   * @returns boolean
   */
  public showAuthOppo: boolean = false;

  /**
   * 机构id
   * @private
   * @returns string
   */
  private get hospitalId(): string {
    return (this.$route.query && (this.$route.query.hospitalId as string)) || "";
  }

  /**
   * 用户授权协议
   * @private
   * @returns void
   */
  private onAuthorization(): void {
    this.showAuthorization = true;
  }

  /* 关闭用户授权协议
   * @private
   * @returns void
   */
  public closeAuthorization() {
    this.showAuthorization = false;
  }

  /* 关闭oppo用户授权协议
   * @private
   * @returns void
   */
  public closeAuthOppo() {
    this.showAuthOppo = false;
  }

  /* 判断是oppo或health
   * @private
   * @returns void
   */
  private onAgreement() {
    if (this.appid == "OPPO") {
      this.showAuthOppo = true;
    } else {
      this.onAuthorization();
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");
    this.appid = appid;
    let employerConfig = globalConfig.employerConfig[appid];
    return employerConfig;
  }

  /**
   * 选中加项id
   * @private
   * @returns any
   */
  private get selectOptionalItem(): any {
    let selectOptionalItemStorage = localStorage.getItem("selectOptionalItem");

    if (selectOptionalItemStorage) {
      return JSON.parse(selectOptionalItemStorage) || [];
    }

    return [];
  }

  /**
   * 获取固定套餐项目个数
   * @private
   *
   * @returns number
   */
  private get fixedNum(): number {
    let sum = 0;

    this.packageInfo &&
      this.packageInfo.baseItemTypes.forEach((item) => {
        sum += (item.items && item.items.length) || 0;
      });

    return sum;
  }

  /**
   * 套餐id
   * @private
   * @returns string
   */
  private get packageId(): string {
    return (this.$route.query && (this.$route.query.packageId as string)) || "";
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get selectUserId(): any {
    let selectUserStorage = localStorage.getItem("selectUser");

    if (selectUserStorage) {
      return JSON.parse(selectUserStorage).employerMemberId || this.userInfo.userId;
    }

    return this.userInfo.userId;
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 页面是否回退-h5环境
   * @private
   * @returns boolean
   */
  private get goback(): boolean {
    if (this.$route.query && this.$route.query.goback) {
      return true;
    }

    return false;
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    let data = {
      hospitalId: null,
      packageId: null,
      optionalItemIds: [],
      healthProductIds: [],
      addOnPackageIds: [],
      employerMemberId: null,
      rightsIds: [],
    };

    data.optionalItemIds = this.selectOptionalItem.optionalItemIds || [];
    data.healthProductIds = this.selectOptionalItem.healthProductIds || [];
    data.addOnPackageIds = this.selectOptionalItem.addOnPackageIds || [];
    data.rightsIds = this.selectOptionalItem.rightsIds || [];
    if (this.hospitalId) {
      data.hospitalId = this.hospitalId;
    }

    data.packageId = this.packageId;
    data.employerMemberId = this.selectUserId;
    this.selectOrderDate = this.date && dayjs(this.date).format("YYYY/MM/DD");

    this.physicalTimePeriod = this.$route.query.physicalTimePeriod as string;

    this.getPreOrderInfo(data);
    this.getCheckIsPerfect();
  }

  /**
   * 取消授权
   * @private
   * @returns void
   */
  private agreementChange() {
    if (!this.checkedAgreement) {
      this.checkedAgreement = true;
      this.showOverlay = true;
    }
  }

  /**
   * 取消授权
   * @private
   * @returns boolean
   */
  private laterView() {
    this.showOverlay = false;
    this.checkedAgreement = false;
  }

  /**
   * 再想想
   */
  protected onAgree(): any {
    this.showOverlay = false;
    this.checkedAgreement = true;
  }

  /**
   * 加项包显示加项
   * @private
   * @returns void
   */
  private async showAddPackageModal(data): Promise<void> {
    this.addItemTitle = data.itemName;
    let { content: res } = await AddPackageService.instance.getAddPackageItem(data.itemId);
    if (res.data) {
      this.addPackItems = res.data;
      this.showAddPackModal = true;
    }
  }

  /**
   * 套餐id
   * @private
   * @returns string
   */
  private get date(): string {
    let date = "";
    if (this.$route.query && this.$route.query.date) {
      date = this.$route.query.date as string;
    }
    return date;
  }

  /**
   * 获取套餐详情
   * @private
   * @returns void
   */
  private async getPreOrderInfo(data: any): Promise<void> {
    try {
      let { content: result } = await OrderService.instance.getPreOrderInfo(data);

      if (result.data) {
        this.packageInfo = result.data.packageInfo;
        this.memberInfo = result.data.memberInfo;
        this.hospitalInfo = result.data.hospitalInfo;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 提交订单
   * @private
   * @returns void
   */
  private onSubmit() {
    if (!this.isPerfect) {
      Dialog.confirm({
        message: "您的个人信息未完善，是否前去完善",
        confirmButtonText: "立即完善",
        cancelButtonText: "稍后完善",
        showCancelButton: false,
      })
        .then(() => {
          this.goToUser();
        })
        .catch(() => {});
      return;
    }
    if (!this.selectOrderDate) {
      Toast("请选择日期");
      return;
    } else {
      this.showDialog = true;
    }
  }

  /**
   * 组件销毁前
   * @private
   * @returns void
   */
  // private beforeDestroy(): void
  // {

  // }

  /**
   * 跳转地图
   * @private
   * @returns void
   */
  private async openMap(hospitalOut: any): Promise<any> {
    if (hospitalOut) {
      let lat = hospitalOut.latitude;
      let lng = hospitalOut.longitude;
      let address = hospitalOut.address;
      let orgName = hospitalOut.hospitalName;

      if (await tools.isWechat()) {
        let currentUrl = location.href;
        let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
        if (result.data) {
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: result.data.appId, // 必填，公众号的唯一标识
            timestamp: result.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
            signature: result.data.signature, // 必填，签名
            jsApiList: ["openLocation"], // 必填，需要使用的JS接口列表
          });

          wx.ready(function () {
            wx.openLocation({
              latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
              longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
              scale: 14, // 缩放比例
              name: orgName,
              address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
              infoUrl: "",
            });
          });
        }
      } else {
        // 腾讯地图
        // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
        // 高德
        let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
        window.open(href);
      }
    }
  }

  /**
   * 日期确认选择
   * @private
   * @returns string
   */
  private onDateConfirm(date: any, period: any): void {
    if (date) {
      this.selectOrderDate = dayjs(date).format("YYYY/MM/DD");
      this.physicalTimePeriod = period;
    }

    this.showDate = false;
  }

  /**
   * 提交订单
   * @private
   * @returns string
   */
  private async onConfirmSubmitOrder(): Promise<any> {
    try {
      if (this.loading) {
        return false;
      }
      this.loading = true;

      let data = {
        employerMemberId: this.selectUserId, // 客户编号
        packageId: this.packageId,
        hospitalId: this.hospitalId, // 医院编号，外键
        optionalItemIds: this.selectOptionalItem.optionalItemIds,
        healthProductIds: this.selectOptionalItem.healthProductIds,
        physicalTime: this.selectOrderDate,
        physicalTimePeriod: this.physicalTimePeriod,
        addOnPackageIds: this.selectOptionalItem.addOnPackageIds,
        employerAuthReadId: (localStorage.getItem("employerAuthId") && +localStorage.getItem("employerAuthId")) || null,
        rightsIds: this.selectOptionalItem.rightsIds || [],
        agreeLicense: this.checkedAgreement,
      };
      let { content: result } = await OrderService.instance.submitOrder(data);

      if (result.data) {
        localStorage.removeItem("selectOptionalItem");
        // 需要支付
        if (result.data.isNeedPay) {
          this.$router.push({ name: "pay", query: { orderNo: result.data.orderNo } });
        } else {
          this.$router.push({
            name: "orderMiddle",
            query: {
              id: result.data.orderId,
              hasHealthProduct: result.data.hasHealthProduct,
              orderStatus: result.data.orderStatus + "",
            },
          });
        }
      }
    } catch (err) {
      Toast(err);
    } finally {
      this.loading = false;
    }
  }

  /**
   * 跳转成功页面
   * @private
   * @returns void
   */
  private onSucessDetail(id: string, hasHealthProduct: boolean): void {
    this.$router.push({ name: "orderMiddle", query: { id: id, hasHealthProduct: hasHealthProduct + "" } });
  }

  /**
   * 跳转订单明细
   * @private
   * @returns void
   */
  private onActionOrderDetail(id: string): void {
    this.$router.push({ name: "orderDetail", params: { id: id } });
  }

  /**
   * 显示排期弹框
   * @private
   * @returns void
   */
  private showDateModal(): void {
    this.showDate = true;
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  /**
   * 显示提示
   * @private
   * @returns void
   */
  private showTip(remark: string): void {
    Dialog.alert({
      messageAlign: "left",
      confirmButtonColor: "#00d5c1",
      message: remark,
      confirmButtonText: "确定",
    });
  }

  /**
   * 获取企业参数配置
   * @private
   * @returns any
   */
  private get generalSettings(): any {
    let generalSettingsValue = localStorage.getItem("generalSettings");
    if (generalSettingsValue) {
      return JSON.parse(generalSettingsValue);
    }
    return [];
  }

  /**
   * 是否显示配置
   * @protected
   * @returns boolean
   */
  protected showStting(code: string): boolean {
    // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
    // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
    let data = this.generalSettings.filter((res) => res.code == code);
    if (data.length > 0) {
      return data[0].isOpen;
    } else {
      return true;
    }
  }

  /**
   * 查询个人信息是否完善
   * @private
   * @returns void
   */
  private async getCheckIsPerfect(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.checkIsPerfect();
      if (result.data) {
        this.isPerfect = result.data.isPerfect;
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }

  /**
   * 跳转用户信息填写页面
   * @private
   * @returns void
   */
  private goToUser(): void {
    if (this.isPerfect) {
      return;
    }
    let query = Object.assign({ userId: this.memberInfo.employerMemberId }, this.$route.query);
    this.$router.push({ name: "family-operation", query: query });
  }
}
