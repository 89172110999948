
import { Component, Vue } from "vue-property-decorator";
import { HftagService, OrganizationService } from "src/services";
import { Toast } from "vant";
import EnumUtils from "src/utils/enum-utils";
import { ORG_TYPE } from "src/enums";
import dayjs from "dayjs";
import globalConfig from "src/config/index";

@Component
export default class Home extends Vue {
  /**
   * 品牌列表
   * @private
   * @returns Array<any>
   */
  private hospBrandList: Array<any> = [];

  /**
   * 日期加载中
   * @private
   * @returns boolean
   */
  private scheduleLoading: boolean = false;

  /**
   * 是否在加载中
   * @private
   * @returns boolean
   */
  private isLoading: boolean = false;

  /**
   * 标签列表
   * @private
   * @returns boolean
   */
  private tagList = [];

  /**
   * 机构类型
   * @private
   * @returns ORG_TYPE
   */
  private ORG_TYPE: ORG_TYPE;

  /**
   * 是否选择城市弹框
   * @private
   * @returns boolean
   */
  private showCity: boolean = false;

  /**
   * 排期列表
   * @private
   * @returns  Array<any>
   */
  private schedulesDataList: Array<any> = [];

  /**
   * 数据列表
   * @private
   * @returns Array<any>
   */
  private dataList: Array<any> = [];

  private allDatalist: Array<any> = [];

  /**
   * 搜索关键字
   * @private
   * @returns string
   */
  private filters: any = {
    key: "",
    packageId: "",
    longitude: "",
    latitude: "",
    brandType: "",
    cityName: "深圳市",
  };

  /**
   * 是否正在加载中
   * @private
   * @returns boolean
   */
  private loading: boolean = false;

  /**
   * 套餐id
   * @private
   * @returns string
   */
  private get packageId(): string {
    return (this.$route.query && (this.$route.query.packageId as string)) || "";
  }

  /**
   * 当前位置
   * @private
   * @returns any
   */
  private get location(): any {
    return this.$store.getters.location;
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 缓存中选中城市
   * @private
   * @returns any
   */
  private get cacheCurrentLocaltion(): any {
    return this.$store.getters.currentLocaltion;
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    this.filters.packageId = this.packageId;
    if (this.location) {
      this.filters.cityName = this.cacheCurrentLocaltion || this.location.city;
      this.filters.latitude = this.location.lat;
      this.filters.longitude = this.location.lng;
    } else if (this.cacheCurrentLocaltion) {
      this.filters.cityName = this.cacheCurrentLocaltion;
    }

    // 获取机构列表
    this.getOrgList(this.filters);
  }

  /*
   * 显示城市组件弹框
   * @private
   * @returns void
   */
  private onShowCityModel(): void {
    this.showCity = true;
  }

  /*
   * 跳转详情
   * @private
   * @returns void
   */
  private onDetail(id: string, item): void {
    let query: any = { id: this.packageId, hospitalId: id };
    this.$router.push({ name: "package-detail", query: query });
  }

  /**
   * 跳转页面
   * @private
   * @returns void
   */
  private onGopage(name: string): void {
    this.$router.push({ name });
  }

  /*
   * 选择城市
   * @private
   * @returns void
   */
  private onSelectCity(city: string): void {
    this.filters.cityName = city;
    this.filters.brandType = "";
    this.getOrgList();
    this.showCity = false;
  }

  /**
   * 获取数据
   * @private
   * @returns void
   */
  private async getOrgList(params: any = {}): Promise<void> {
    this.isLoading = true;

    let filters = Object.assign(this.filters, params);
    try {
      let { content: result } = await OrganizationService.instance.getHospitalList(filters);
      if (result.data) {
        this.allDatalist = result.data.hospitals;
        this.dataList = JSON.parse(JSON.stringify(result.data.hospitals));
        this.hospBrandList = result.data.brands;
        this.loading = false;
      }
      this.allDatalist.forEach((res) => {
        this.$set(res, "isLoading", true);
        this.getSupportHospitalSchedules(res.id);
      });
      this.dataList.forEach((res) => {
        this.$set(res, "isLoading", true);
      });
    } catch (err) {
      Toast(err);
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * 获取机构列表排期
   * @private
   * @returns void
   */
  private async getSupportHospitalSchedules(hospitalId: any): Promise<void> {
    let curHospital = this.allDatalist.filter((res) => res.id === hospitalId);
    if (curHospital && curHospital.length) {
      curHospital[0].schedules = [];
    }

    let curHospitalData = this.dataList.filter((res) => res.id === hospitalId);
    if (curHospitalData && curHospitalData.length) {
      curHospitalData[0].schedules = [];
    }
    try {
      this.scheduleLoading = true;
      let { content: result } = await OrganizationService.instance.getSupportHospitalSchedules({
        packageId: this.filters.packageId,
        hospitalIds: [hospitalId],
      });
      if (result.data) {
        this.allDatalist &&
          this.allDatalist.forEach((res) => {
            let row = result.data.filter((item) => item.hospitalId == res.id);
            if (row && row.length) {
              res.schedules = row[0].schedules;
              res.isLoading = false;
            }
          });
        this.dataList &&
          this.dataList.forEach((res) => {
            let row = result.data.filter((item) => item.hospitalId == res.id);
            if (row && row.length) {
              res.schedules = row[0].schedules;
              res.isLoading = false;
            }
          });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 触发搜索城市事件
   * @private
   * @returns string
   */
  private onSearchCity(val): void {
    this.filters.key = val;
    this.onSearch();
  }

  /**
   * 切换tab
   * @private
   * @param {number} number
   * @returns void
   */
  private onTabChange(id: any): void {
    this.filters.brandType = id;
    this.onSearch();
  }

  /**
   * 搜索
   * @private
   * @param {number} number
   * @returns void
   */
  private onSearch(): void {
    this.dataList = this.allDatalist;
    if (this.filters.key) {
      this.dataList = this.dataList.filter((item) => item.name.indexOf(this.filters.key) >= 0);
    }

    if (this.filters.brandType !== "") {
      this.dataList = this.dataList.filter((item) => item.brandType === this.filters.brandType);
    }
  }

  /**
   * 取消搜索城市事件
   * @private
   * @returns void
   */
  private onClearCityRseult(): void {
    this.filters.key = "";
    this.onSearch();
  }

  /**
   * 显示日历空间日期
   * @private
   * @returns void
   */
  private handlerCalendar(scheduleModel: any): Array<any> {
    let schedulesObj = {};
    scheduleModel &&
      scheduleModel.schedules &&
      scheduleModel.schedules.forEach((item) => {
        schedulesObj[dayjs(item.startTime).format("YYYY-MM-DD")] = item.personCount;
      });

    let dateList = [];

    for (let i = 0; i < 10; i++) {
      let current = dayjs().add(i, "day").format("YYYY-MM-DD");
      let num = 0;
      if (schedulesObj[current] && schedulesObj[current] > 0) {
        num = schedulesObj[current];
      }

      let obj = {
        date: current,
        num: num,
        txt: dayjs(current).format("MM.DD"),
      };
      dateList.push(obj);
    }

    return dateList;
  }
}
